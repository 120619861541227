<template>
  <div>
    <div
      class="row mt-5 w-85 big-div"
      style="position: relative; z-index: 100"
      :style="
        getPageDesign() +
        `zoom:${this.pageZoomValue ? this.pageZoomValue : 90}%;`
      "
    >
      <div
        class="bg"
        v-if="getImageAlign == 'no'"
        :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
        :style="
          getbackgroundImage(
            IsImageAdded,
            pageDesign.imageBlock,
            getImageAlign,
            isDesktopView
          ) + getBgDivStyles()
        "
      ></div>
      <!-- Image Align Left -->
      <div
        class="col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0"
        v-show="showLeftImage"
        :class="{
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division': isDesktopView,
        }"
        :style="
          calculateImageWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive
          )
        "
      >
        <div
          @click="activeImageCustomize"
          :class="isDesktopView ? 'image-division' : ''"
        >
          <div :style="height100(isDesktopView)">
            <LazyImage
              :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
              :style="
                getUniversalImageStyle(
                  isDesktopView,
                  pageDesign.imageBlock.style,
                  IsImageVariableWidthActive,
                  pageDesign.imageBlock
                ) +
                getImageRotation(pageDesign.imageBlock.style) +
                getImageOpacity(pageDesign.imageBlock.style)
              "
              :class="[
                `pic-${getSelectedPage.order}`,
                getImageSrc(pageDesign.imageBlock, isDesktopView) ==
                '/images/default_start_page.png'
                  ? 'default-bg'
                  : '',
              ]"
              :src="
                MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))
              "
              alt
            />
          </div>
        </div>
        <button id="clear" class="hide close-icon" @click="deleteImage">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
              fill="black"
              fill-opacity="0.5"
            />
            <path
              d="M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z"
              fill="white"
            />
          </svg>
        </button>
      </div>

      <!-- Main Section -->
      <div
        :class="!IsImageAdded ? 'col-12' : imageAddedLayout"
        :style="
          getMainPaddingCss(pageDesign.pageStyles, isDesktopView) +
          getScrollCSS(
            pageDesign.pageStyles,
            isDesktopView,
            pageDesign.imageBlock.style,
            pageDesign.pageType
          ) +
          calculateContentWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive,
            pageDesign.pageStyles
          )
        "
      >
        <div
          class=""
          style="
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
          "
        >
          <div style="overflow: hidden scroll" class="h-100">
            <div>
              <ProgressBar :order="CurrentPage.order" v-if="!isBearWithBenefitsClient" />
            </div>
            <!-- v-model="pageDesign.blocksArray" -->
            <draggable
              v-model="pageDesignBlocks"
              class="dragArea list-group"
              group="actions"
              v-bind="dragOptions"
              chosen-class="chosen"
              drag-class="drag"
              @start="dragging = true"
              @end="dragging = false"
              style="cursor: move"
            >
              <div
                v-for="(pro, ind) in pageDesign.blocksArray"
                :key="`${pro.type}-${ind}`"
                draggable="false"
                :class="(dragging && pro.type != 'button') ? 'dragElementBorder' : null"
                @drop="handleElementDrop($event,ind)"
                 
                 @dragover.prevent
                @dragenter.prevent
                @click="changeCustomize(ind)"
              >
                <div
                :id="`${pro.type}-${ind}`"
                :class="pro.type !== 'button' ? 'designBlockElement':null"
                  :style="getHeightWidth(pro.style) + getDivBorderCss(pro)"
                  class="content"
                  style="position: relative"
                >
                <EditorAction v-if="pro.customizeStatus" :deleteCondition="getDeleteButtonCondition(pro)" :currElementIndex="ind" :currElementType="pro.type"/>
                
                  <div
                    v-if="pro.type == 'text'"
                    :style="
                      getColorCss(pro.style) +
                      getPaddingCss(pro.style) +
                      getMarginCss(pro.style, isDesktopView) +
                      getColorWithOpacity(pro.style) +
                      getTextRotate(pro.style) +
                      getLineHeight(pro.style) +
                      getTextAlign(pro.style) +
                      getFontStyle(pro.style,isDesktopView)
                    "
                    v-html="getEditorPersonalisedValueStyle(pro.content)"
                  ></div>
                  <div
                    v-if="pro.type == 'html'"
                    :style="
                      getMarginCss(pro.style, isDesktopView) +
                      getColorWithOpacity(pro.style) +
                      getTextRotate(pro.style) +
                      getTextAlign(pro.style)
                    "
                  >
                    <HtmlElement :pro="pro" :isDesktopView="isDesktopView" />
                  </div>
                  <!-- </VueDragResize> -->

                  <!-- Free Text Input -->

                  <div v-if="pro.type == 'freeText'" class="FreetextBlock">
                    <div
                      :style="
                        getMarginCss(pro.style, isDesktopView) +
                        getTextAlign(pro.style)
                      "
                    >
                      <div class="mb-1" v-if="pro.showLabel">
                        <p
                          class="m-0 p-0 text-left"
                          :style="leadLabelStyle(pro.style)"
                        >
                          {{ pro.labelText }}
                          <span v-if="pro.isRequired" style="color: red"
                            >*</span
                          >
                        </p>
                      </div>
                      <div
                        :style="
                          pro.style.borderType === 'border'
                            ? getInputBorderCSS(pro.style)
                            : getInputBorderBottomCSS(pro.style)
                        "
                      >
                        <input
                          v-if="
                            pro.selectedInfoType.fieldName !== 'date' &&
                            pro.selectedInfoType.fieldName !== 'phone_number'
                          "
                          :type="pro.selectedInfoType.type.toLowerCase()"
                          :placeholder="pro.InfoTypePlaceholder"
                          :required="pro.isRequired"
                          :style="customInputStyles(pro.style)"
                          class="text-dark font-weight-medium date-input CustomTextInput"
                        />

                        <div
                          v-else-if="
                            pro.selectedInfoType.fieldName !== 'date' &&
                            pro.selectedInfoType.fieldName == 'phone_number'
                          "
                        >
                          <VueTelInput
                            :inputOptions="{
                              placeholder: pro.InfoTypePlaceholder,
                              maxlength: 15,
                            }"
                            :dropdownOptions="dropdown"
                            mode="international"
                            :validCharactersOnly="true"
                            :style="customInputStyles(pro.style)"
                            class="text-dark font-weight-medium date-input CustomTextInput telephoneInput"
                          />
                        </div>
                        <div v-else>
                          <div class="row m-0 p-0" v-if="pro.isDatePicker">
                            <!-- <b-form-datepicker :label-no-date-selected="pro.selectedInfoType.datePickerPlaceholder" reset-button class="CustomDatePicker"  :style="customInputStyles(pro.style)"></b-form-datepicker> -->
                            <div @click="openCalender" class="w-100">
                              <input
                                class="form-control defaultCalendar"
                                type="date"
                                :style="customInputStyles(pro.style)"
                                :placeholder="
                                  pro.selectedInfoType.datePickerPlaceholder
                                "
                                ref="CustomDateInputRef"
                              />
                            </div>
                          </div>
                          <div class="row m-0 p-0" v-else>
                            <div
                              class="d-flex"
                              v-if="pro.selectedDateFormat == 'DDMMYYYY'"
                            >
                              <div class="d-flex flex-column px-1">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.dateText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="
                                      pro.selectedInfoType.type.toLowerCase()
                                    "
                                    placeholder="DD"
                                    :required="pro.isRequired"
                                    :min="1"
                                    :style="customInputStyles(pro.style)"
                                    :max="31"
                                    v-model="dateInputValue"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                              <!-- seperator -->
                              <div class="d-flex justify-content-center mr-2">
                                <span
                                  class="mb-0"
                                  style="align-self: end"
                                  :style="getDateTextColor(pro.style)"
                                  >{{ pro.selectedDateFormatSeperator }}</span
                                >
                              </div>
                              <!-- seperator -->
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.monthText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="
                                      pro.selectedInfoType.type.toLowerCase()
                                    "
                                    placeholder="MM"
                                    v-model="monthInputValue"
                                    :required="pro.isRequired"
                                    :min="1"
                                    :style="customInputStyles(pro.style)"
                                    :max="12"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                              <!-- seperator -->
                              <div class="d-flex justify-content-center mr-2">
                                <span
                                  class="mb-0"
                                  style="align-self: end"
                                  :style="getDateTextColor(pro.style)"
                                  >{{ pro.selectedDateFormatSeperator }}</span
                                >
                              </div>
                              <!-- seperator -->
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.yearText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="
                                      pro.selectedInfoType.type.toLowerCase()
                                    "
                                    placeholder="YYYY"
                                    v-model="yearInputValue"
                                    :min="1900"
                                    :max="9999"
                                    :style="customInputStyles(pro.style)"
                                    :required="pro.isRequired"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="d-flex"
                              v-if="pro.selectedDateFormat == 'MMDDYYYY'"
                            >
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.monthText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="
                                      pro.selectedInfoType.type.toLowerCase()
                                    "
                                    placeholder="MM"
                                    v-model="monthInputValue"
                                    :required="pro.isRequired"
                                    :min="1"
                                    :style="customInputStyles(pro.style)"
                                    :max="12"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                              <!-- seperator -->
                              <div class="d-flex justify-content-center mr-2">
                                <span
                                  class="mb-0"
                                  style="align-self: end"
                                  :style="getDateTextColor(pro.style)"
                                  >{{ pro.selectedDateFormatSeperator }}</span
                                >
                              </div>
                              <!-- seperator -->
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.dateText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="
                                      pro.selectedInfoType.type.toLowerCase()
                                    "
                                    placeholder="DD"
                                    :required="pro.isRequired"
                                    :min="1"
                                    v-model="dateInputValue"
                                    :style="customInputStyles(pro.style)"
                                    :max="31"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>

                              <!-- seperator -->
                              <div class="d-flex justify-content-center mr-2">
                                <span
                                  class="mb-0"
                                  style="align-self: end"
                                  :style="getDateTextColor(pro.style)"
                                  >{{ pro.selectedDateFormatSeperator }}</span
                                >
                              </div>
                              <!-- seperator -->
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.yearText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="
                                      pro.selectedInfoType.type.toLowerCase()
                                    "
                                    placeholder="YYYY"
                                    v-model="yearInputValue"
                                    :min="1900"
                                    :style="customInputStyles(pro.style)"
                                    :max="9999"
                                    :required="pro.isRequired"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="d-flex"
                              v-if="pro.selectedDateFormat == 'YYYYMMDD'"
                            >
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.yearText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="
                                      pro.selectedInfoType.type.toLowerCase()
                                    "
                                    placeholder="YYYY"
                                    v-model="yearInputValue"
                                    :min="1900"
                                    :max="9999"
                                    :style="customInputStyles(pro.style)"
                                    :required="pro.isRequired"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                              <!-- seperator -->
                              <div class="d-flex justify-content-center mr-2">
                                <span
                                  class="mb-0"
                                  style="align-self: end"
                                  :style="getDateTextColor(pro.style)"
                                  >{{ pro.selectedDateFormatSeperator }}</span
                                >
                              </div>
                              <!-- seperator -->
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.monthText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="
                                      pro.selectedInfoType.type.toLowerCase()
                                    "
                                    placeholder="MM"
                                    v-model="monthInputValue"
                                    :required="pro.isRequired"
                                    :min="1"
                                    :max="12"
                                    :style="customInputStyles(pro.style)"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                              <!-- seperator -->
                              <div class="d-flex justify-content-center mr-2">
                                <span
                                  class="mb-0"
                                  style="align-self: end"
                                  :style="getDateTextColor(pro.style)"
                                  >{{ pro.selectedDateFormatSeperator }}</span
                                >
                              </div>
                              <!-- seperator -->
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.dateText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="
                                      pro.selectedInfoType.type.toLowerCase()
                                    "
                                    placeholder="DD"
                                    v-model="dateInputValue"
                                    :required="pro.isRequired"
                                    :min="1"
                                    :max="31"
                                    :style="customInputStyles(pro.style)"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="d-flex"
                              v-if="pro.selectedDateFormat == 'DDMM'"
                            >
                              <div class="d-flex flex-column px-1">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.dateText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="
                                      pro.selectedInfoType.type.toLowerCase()
                                    "
                                    placeholder="DD"
                                    :required="pro.isRequired"
                                    :min="1"
                                    :style="customInputStyles(pro.style)"
                                    :max="31"
                                    v-model="dateInputValue"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                              <!-- seperator -->
                              <div class="d-flex justify-content-center mr-2">
                                <span
                                  class="mb-0"
                                  style="align-self: end"
                                  :style="getDateTextColor(pro.style)"
                                  >{{ pro.selectedDateFormatSeperator }}</span
                                >
                              </div>
                              <!-- seperator -->
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.monthText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="
                                      pro.selectedInfoType.type.toLowerCase()
                                    "
                                    placeholder="MM"
                                    v-model="monthInputValue"
                                    :required="pro.isRequired"
                                    :min="1"
                                    :style="customInputStyles(pro.style)"
                                    :max="12"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="d-flex"
                              v-if="pro.selectedDateFormat == 'MMDD'"
                            >
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.monthText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="
                                      pro.selectedInfoType.type.toLowerCase()
                                    "
                                    placeholder="MM"
                                    v-model="monthInputValue"
                                    :required="pro.isRequired"
                                    :min="1"
                                    :style="customInputStyles(pro.style)"
                                    :max="12"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                              <!-- seperator -->
                              <div class="d-flex justify-content-center mr-2">
                                <span
                                  class="mb-0"
                                  style="align-self: end"
                                  :style="getDateTextColor(pro.style)"
                                  >{{ pro.selectedDateFormatSeperator }}</span
                                >
                              </div>
                              <!-- seperator -->
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.dateText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="
                                      pro.selectedInfoType.type.toLowerCase()
                                    "
                                    placeholder="DD"
                                    :required="pro.isRequired"
                                    :min="1"
                                    v-model="dateInputValue"
                                    :style="customInputStyles(pro.style)"
                                    :max="31"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Custom Input -->

                  <div v-if="pro.type == 'customInput'" class="FreetextBlock">
                    <div
                      :style="
                        getMarginCss(pro.style, isDesktopView) +
                        getTextAlign(pro.style)
                      "
                    >
                      <div class="mb-1" v-if="pro.showLabel">
                        <p
                          class="m-0 p-0 text-left"
                          :style="leadLabelStyle(pro.style)"
                        >
                          {{ pro.labelText }}
                          <span v-if="pro.isRequired" style="color: red"
                            >*</span
                          >
                        </p>
                      </div>
                      <div
                        :style="
                          pro.style.borderType === 'border'
                            ? getInputBorderCSS(pro.style)
                            : getInputBorderBottomCSS(pro.style)
                        "
                      >
                        <input
                          v-if="pro.selectedInfoType.type == 'text'"
                          :type="pro.selectedInfoType.type.toLowerCase()"
                          :placeholder="pro.InfoTypePlaceholder"
                          :required="pro.isRequired"
                          :style="customInputStyles(pro.style)"
                          class="text-dark font-weight-medium date-input CustomTextInput"
                        />

                        <div v-if="pro.selectedInfoType.type == 'textarea'">
                          <textarea
                            type="text"
                            :placeholder="pro.InfoTypePlaceholder"
                            :required="pro.isRequired"
                            :style="freeTextInputStyles(pro.style)"
                            id="textInput"
                            rows="1"
                            style="overflow: auto; resize: none"
                            class="text-input p-1"
                            @keyup="InputAdjust"
                            @focus="InputAdjust"
                          />
                          <!--   -->
                        </div>
                        <div v-if="pro.selectedInfoType.type == 'date'">
                          <div class="row m-0 p-0" v-if="pro.isDatePicker">
                            <!-- <b-form-datepicker :label-no-date-selected="pro.selectedInfoType.datePickerPlaceholder" reset-button class="CustomDatePicker"  :style="customInputStyles(pro.style)"></b-form-datepicker> -->
                            <div @click="openCalender" class="w-100">
                              <input
                                class="form-control defaultCalendar"
                                type="date"
                                :style="customInputStyles(pro.style)"
                                :placeholder="
                                  pro.selectedInfoType.datePickerPlaceholder
                                "
                                ref="CustomDateInputRef"
                              />
                            </div>
                          </div>
                          <div class="row m-0 p-0" v-else>
                            <div
                              class="d-flex"
                              v-if="pro.selectedDateFormat == 'DDMMYYYY'"
                            >
                              <div class="d-flex flex-column px-1">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.dateText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="`text`"
                                    placeholder="DD"
                                    :required="pro.isRequired"
                                    :min="1"
                                    :style="customInputStyles(pro.style)"
                                    :max="31"
                                    v-model="dateCustomInputValue"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                              <!-- seperator -->
                              <div class="d-flex justify-content-center mr-2">
                                <span
                                  class="mb-0"
                                  style="align-self: end"
                                  :style="getDateTextColor(pro.style)"
                                  >{{ pro.selectedDateFormatSeperator }}</span
                                >
                              </div>
                              <!-- seperator -->
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.monthText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="`text`"
                                    placeholder="MM"
                                    v-model="monthCustomInputValue"
                                    :required="pro.isRequired"
                                    :min="1"
                                    :style="customInputStyles(pro.style)"
                                    :max="12"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                              <!-- seperator -->
                              <div class="d-flex justify-content-center mr-2">
                                <span
                                  class="mb-0"
                                  style="align-self: end"
                                  :style="getDateTextColor(pro.style)"
                                  >{{ pro.selectedDateFormatSeperator }}</span
                                >
                              </div>
                              <!-- seperator -->
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.yearText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="`text`"
                                    placeholder="YYYY"
                                    v-model="yearCustomInputValue"
                                    :min="1900"
                                    :max="9999"
                                    :style="customInputStyles(pro.style)"
                                    :required="pro.isRequired"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="d-flex"
                              v-if="pro.selectedDateFormat == 'MMDDYYYY'"
                            >
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.monthText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="`text`"
                                    placeholder="MM"
                                    v-model="monthCustomInputValue"
                                    :required="pro.isRequired"
                                    :min="1"
                                    :style="customInputStyles(pro.style)"
                                    :max="12"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                              <!-- seperator -->
                              <div class="d-flex justify-content-center mr-2">
                                <span
                                  class="mb-0"
                                  style="align-self: end"
                                  :style="getDateTextColor(pro.style)"
                                  >{{ pro.selectedDateFormatSeperator }}</span
                                >
                              </div>
                              <!-- seperator -->
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.dateText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="`text`"
                                    placeholder="DD"
                                    :required="pro.isRequired"
                                    :min="1"
                                    v-model="dateCustomInputValue"
                                    :style="customInputStyles(pro.style)"
                                    :max="31"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>

                              <!-- seperator -->
                              <div class="d-flex justify-content-center mr-2">
                                <span
                                  class="mb-0"
                                  style="align-self: end"
                                  :style="getDateTextColor(pro.style)"
                                  >{{ pro.selectedDateFormatSeperator }}</span
                                >
                              </div>
                              <!-- seperator -->
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.yearText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="`text`"
                                    placeholder="YYYY"
                                    v-model="yearCustomInputValue"
                                    :min="1900"
                                    :style="customInputStyles(pro.style)"
                                    :max="9999"
                                    :required="pro.isRequired"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="d-flex"
                              v-if="pro.selectedDateFormat == 'YYYYMMDD'"
                            >
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.yearText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="`text`"
                                    placeholder="YYYY"
                                    v-model="yearCustomInputValue"
                                    :min="1900"
                                    :max="9999"
                                    :style="customInputStyles(pro.style)"
                                    :required="pro.isRequired"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                              <!-- seperator -->
                              <div class="d-flex justify-content-center mr-2">
                                <span
                                  class="mb-0"
                                  style="align-self: end"
                                  :style="getDateTextColor(pro.style)"
                                  >{{ pro.selectedDateFormatSeperator }}</span
                                >
                              </div>
                              <!-- seperator -->
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.monthText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="`text`"
                                    placeholder="MM"
                                    v-model="monthCustomInputValue"
                                    :required="pro.isRequired"
                                    :min="1"
                                    :max="12"
                                    :style="customInputStyles(pro.style)"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                              <!-- seperator -->
                              <div class="d-flex justify-content-center mr-2">
                                <span
                                  class="mb-0"
                                  style="align-self: end"
                                  :style="getDateTextColor(pro.style)"
                                  >{{ pro.selectedDateFormatSeperator }}</span
                                >
                              </div>
                              <!-- seperator -->
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.dateText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="`text`"
                                    placeholder="DD"
                                    v-model="dateCustomInputValue"
                                    :required="pro.isRequired"
                                    :min="1"
                                    :max="31"
                                    :style="customInputStyles(pro.style)"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="d-flex"
                              v-if="pro.selectedDateFormat == 'DDMM'"
                            >
                              <div class="d-flex flex-column px-1">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.dateText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="`text`"
                                    placeholder="DD"
                                    :required="pro.isRequired"
                                    :min="1"
                                    :style="customInputStyles(pro.style)"
                                    :max="31"
                                    v-model="dateCustomInputValue"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                              <!-- seperator -->
                              <div class="d-flex justify-content-center mr-2">
                                <span
                                  class="mb-0"
                                  style="align-self: end"
                                  :style="getDateTextColor(pro.style)"
                                  >{{ pro.selectedDateFormatSeperator }}</span
                                >
                              </div>
                              <!-- seperator -->
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.monthText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="`text`"
                                    placeholder="MM"
                                    v-model="monthCustomInputValue"
                                    :required="pro.isRequired"
                                    :min="1"
                                    :style="customInputStyles(pro.style)"
                                    :max="12"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="d-flex"
                              v-if="pro.selectedDateFormat == 'MMDD'"
                            >
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.monthText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="`text`"
                                    placeholder="MM"
                                    v-model="monthCustomInputValue"
                                    :required="pro.isRequired"
                                    :min="1"
                                    :style="customInputStyles(pro.style)"
                                    :max="12"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                              <!-- seperator -->
                              <div class="d-flex justify-content-center mr-2">
                                <span
                                  class="mb-0"
                                  style="align-self: end"
                                  :style="getDateTextColor(pro.style)"
                                  >{{ pro.selectedDateFormatSeperator }}</span
                                >
                              </div>
                              <!-- seperator -->
                              <div class="d-flex flex-column">
                                <div class="d-inline" style="text-align: left">
                                  <span
                                    :style="
                                      getDateTextColor(pro.style) +
                                      getDateTextFontStyle(pro.style)
                                    "
                                    >{{ pro.selectedInfoType.dateText }}</span
                                  >
                                </div>

                                <div>
                                  <input
                                    :type="`text`"
                                    placeholder="DD"
                                    :required="pro.isRequired"
                                    :min="1"
                                    v-model="dateCustomInputValue"
                                    :style="customInputStyles(pro.style)"
                                    :max="31"
                                    class="text-dark font-weight-medium date-input"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Limit Characters Div start-->

                      <div
                        v-if="
                          pro.selectedInfoType.type == 'textarea' &&
                          Object.prototype.hasOwnProperty.call(
                            pro,
                            `limitFreeTextArea`
                          ) &&
                          pro.limitFreeTextArea.limitFreeTextAreaValue
                        "
                        class="text-right pr-2"
                      >
                        <span
                          v-if="
                            pro.limitFreeTextArea.limitSelectionType == 'max'
                          "
                          :style="`color:${pro.style.color};opacity:${pro.style.textOpacity};`"
                          >{{ leadTextValue.length }}/{{
                            pro.limitFreeTextArea.maxFreeTextAreaValue
                          }}</span
                        >
                        <span
                          v-if="
                            pro.limitFreeTextArea.limitSelectionType == 'min'
                          "
                          :style="`color:${pro.style.color};opacity:${pro.style.textOpacity};`"
                          >{{ pro.limitFreeTextArea.minFreeTextAreaValue }}/{{
                            leadTextValue.length
                          }}</span
                        >
                        <span
                          v-if="
                            pro.limitFreeTextArea.limitSelectionType == 'exact'
                          "
                          :style="`color:${pro.style.color};opacity:${pro.style.textOpacity};`"
                          >{{
                            pro.limitFreeTextArea.exactFreeTextAreaValue
                          }}</span
                        >
                      </div>
                      <!-- Limit Characters Div end-->
                    </div>
                  </div>

                  <!-- checkbox -->
                  <div v-if="pro.type == 'checkbox'">
                    <div
                      :style="
                        getMarginCss(pro.style, isDesktopView) +
                        getTextAlign(pro.style)
                      "
                    >
                      <div
                        class="d-flex align-items-center"
                        :style="getcheckboxRotate(pro.style)"
                      >
                        <div
                          class="form-check d-flex align-items-center m-0 p-0 w-100 checkboxDiv"
                          :style="`justify-content:${
                            pro.style.textAlign
                          };flex-direction:${
                            pro.style.textAlign == 'end' ? `row-reverse` : `row`
                          };`"
                        >
                          <input
                            type="checkbox"
                            class="form-check-input m-0"
                            :class="
                              pro.style.textAlign == 'end' ? `ml-2` : `mr-2`
                            "
                            :id="`customCheckbox-${Date.now()}`"
                            :style="{
                              height: pro.style.fontSize + 'px',
                              width: pro.style.fontSize + 'px',
                              position: 'relative',
                            }"
                          />
                          <label
                            class="form-check-label m-0"
                            :for="`customCheckbox-${Date.now()}`"
                            :style="
                              getColorCss(pro.style) +
                              getColorWithOpacity(pro.style) +
                              getFontStyle(pro.style)
                            "
                            ><span
                              >{{ pro.text }}
                              <a
                                class=""
                                style="
                                  text-decoration: underline;
                                  cursor: pointer;
                                "
                                :style="getLinkStyle(pro.style)"
                                @click="goToURLClicked(pro.link)"
                                target="_blank"
                                >{{ pro.linkText }}</a
                              ></span
                            >
                            <span v-if="pro.isRequired" style="color: red"
                              >*</span
                            >
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- checkbox -->
                  <div v-if="pro.type == 'freeTextArea'">
                    <div
                      :style="
                        getMarginCss(pro.style, isDesktopView) +
                        getTextAlign(pro.style)
                      "
                    >
                      <div class="mb-1" v-if="pro.showLabel">
                        <p
                          class="m-0 p-0 text-left"
                          :style="leadLabelStyle(pro.style)"
                        >
                          {{ pro.labelText }}
                          <span v-if="pro.isRequired" style="color: red"
                            >*</span
                          >
                        </p>
                      </div>
                      <div
                        class=" "
                        :style="
                          pro.style.borderType === 'border'
                            ? getInputBorderCSS(pro.style)
                            : getInputBorderBottomCSS(pro.style)
                        "
                      >
                        <textarea
                          type="text"
                          :placeholder="pro.InfoTypePlaceholder"
                          :required="pro.isRequired"
                          :style="freeTextInputStyles(pro.style)"
                          id="textInput"
                          rows="1"
                          style="resize: none; overflow: auto"
                          class="text-input p-1"
                          @keyup="InputAdjust"
                          @focus="InputAdjust"
                        />
                      </div>

                      <!-- Limit Characters Div start-->

                      <div
                        v-if="
                          Object.prototype.hasOwnProperty.call(
                            pro,
                            `limitFreeTextArea`
                          ) && pro.limitFreeTextArea.limitFreeTextAreaValue
                        "
                        class="text-right pr-2"
                      >
                        <span
                          v-if="
                            pro.limitFreeTextArea.limitSelectionType == 'max'
                          "
                          :style="`color:${pro.style.color};opacity:${pro.style.textOpacity};`"
                          >{{ leadTextValue.length }}/{{
                            pro.limitFreeTextArea.maxFreeTextAreaValue
                          }}</span
                        >
                        <span
                          v-if="
                            pro.limitFreeTextArea.limitSelectionType == 'min'
                          "
                          :style="`color:${pro.style.color};opacity:${pro.style.textOpacity};`"
                          >{{ pro.limitFreeTextArea.minFreeTextAreaValue }}/{{
                            leadTextValue.length
                          }}</span
                        >
                        <span
                          v-if="
                            pro.limitFreeTextArea.limitSelectionType == 'exact'
                          "
                          :style="`color:${pro.style.color};opacity:${pro.style.textOpacity};`"
                          >{{
                            pro.limitFreeTextArea.exactFreeTextAreaValue
                          }}</span
                        >
                      </div>
                      <!-- Limit Characters Div end-->
                    </div>
                  </div>

                  <!-- Auth -->
                  <div v-if="pro.type == 'Auth'">
                    <div :style="getMarginCss(pro.style, isDesktopView)">
                      <div class="mb-1" v-if="pro.showLabel">
                        <p class="" :style="verifyOtpTextStyles(pro.style)">
                          {{ pro.VerifyOTPText }}
                        </p>
                      </div>

                      <div>
                        <AuthOtpInputComp :pro="pro" class="mb-3" />
                        <div
                          class="d-flex"
                          :style="`justify-content:${pro.style.sendBtnPosition};`"
                        >
                          <div class="d-flex flex-column">
                            <button
                              class="btn"
                              :style="authButtonStyles(pro.style)"
                            >
                              {{ pro.verifyAuthText }}
                            </button>

                            <button
                              class="btn pb-0"
                              style="font-size: 14px"
                              :style="`color:${pro.style.strokeColor}`"
                            >
                              <BIconArrowLeft class="mr-2" />
                              {{ pro.goBackBtnText }}
                            </button>
                            <button
                              class="btn p-0"
                              style="font-size: 14px"
                              :style="`color:${pro.style.strokeColor}`"
                            >
                              {{ pro.resendOtpText }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Auth end-->

                  <!-- Address Start -->

                  <div v-if="pro.type == 'address'">
                    <div
                      :style="
                        getMarginCss(pro.style, isDesktopView) +
                        getTextAlign(pro.style)
                      "
                    >
                      <div v-if="pro.showAddressLine1">
                        <div class="mb-1" v-if="pro.showAddressLine1Label">
                          <p class="m-0 p-0" :style="leadLabelStyle(pro.style)">
                            <span
                              v-if="
                                pro.isAddressLine1Required &&
                                pro.style.textInputAlign == 'end'
                              "
                              style="color: red"
                              >*</span
                            >
                            {{ pro.AddressLine1Label }}
                            <span
                              v-if="
                                pro.isAddressLine1Required &&
                                (pro.style.textInputAlign == 'center' ||
                                  pro.style.textInputAlign == 'left')
                              "
                              style="color: red"
                              >*</span
                            >
                          </p>
                        </div>
                        <div
                          :style="
                            pro.style.borderType === 'border'
                              ? getInputBorderCSS(pro.style)
                              : getInputBorderBottomCSS(pro.style)
                          "
                        >
                          <input
                            type="text"
                            :placeholder="pro.AddressLine1Placeholder"
                            :required="pro.isAddressLine1Required"
                            :style="customInputStyles(pro.style)"
                            class="text-dark font-weight-medium date-input CustomTextInput"
                          />
                        </div>
                      </div>
                      <div v-if="pro.showAddressLine2" class="my-2">
                        <div class="mb-1" v-if="pro.showAddressLine2Label">
                          <p class="m-0 p-0" :style="leadLabelStyle(pro.style)">
                            <span
                              v-if="
                                pro.isAddressLine2Required &&
                                pro.style.textInputAlign == 'end'
                              "
                              style="color: red"
                              >*</span
                            >
                            {{ pro.AddressLine2Label }}
                            <span
                              v-if="
                                pro.isAddressLine2Required &&
                                (pro.style.textInputAlign == 'center' ||
                                  pro.style.textInputAlign == 'left')
                              "
                              style="color: red"
                              >*</span
                            >
                          </p>
                        </div>
                        <div
                          :style="
                            pro.style.borderType === 'border'
                              ? getInputBorderCSS(pro.style)
                              : getInputBorderBottomCSS(pro.style)
                          "
                        >
                          <input
                            type="text"
                            :placeholder="pro.AddressLine2Placeholder"
                            :required="pro.isAddressLine2Required"
                            :style="customInputStyles(pro.style)"
                            class="text-dark font-weight-medium date-input CustomTextInput"
                          />
                        </div>
                      </div>
                      <div v-if="pro.showZipCode" class="my-2">
                        <div class="mb-1" v-if="pro.showZipCodeLabel">
                          <p class="m-0 p-0" :style="leadLabelStyle(pro.style)">
                            <span
                              v-if="
                                pro.isZipCodeRequired &&
                                pro.style.textInputAlign == 'end'
                              "
                              style="color: red"
                              >*</span
                            >
                            {{ pro.ZipCodeLabel }}
                            <span
                              v-if="
                                pro.isZipCodeRequired &&
                                (pro.style.textInputAlign == 'center' ||
                                  pro.style.textInputAlign == 'left')
                              "
                              style="color: red"
                              >*</span
                            >
                          </p>
                        </div>
                        <div
                          :style="
                            pro.style.borderType === 'border'
                              ? getInputBorderCSS(pro.style)
                              : getInputBorderBottomCSS(pro.style)
                          "
                        >
                          <input
                            type="text"
                            :placeholder="pro.ZipCodePlaceholder"
                            :required="pro.isZipCodeRequired"
                            :style="customInputStyles(pro.style)"
                            class="text-dark font-weight-medium date-input CustomTextInput"
                          />
                        </div>
                      </div>
                      <div v-if="pro.showCity" class="my-2">
                        <div
                          class="mb-1 d-flex align-items-center"
                          v-if="pro.showCityLabel"
                        >
                          <p class="m-0 p-0" :style="leadLabelStyle(pro.style)">
                            <span
                              v-if="
                                pro.isCityRequired &&
                                pro.style.textInputAlign == 'end'
                              "
                              style="color: red"
                              >*</span
                            >
                            {{ pro.CityLabel }}
                            <span
                              v-if="
                                pro.isCityRequired &&
                                (pro.style.textInputAlign == 'center' ||
                                  pro.style.textInputAlign == 'left')
                              "
                              style="color: red"
                              >*</span
                            >
                          </p>
                          <div
                            v-if="CityLoading"
                            :style="`color:${pro.style.color};`"
                            class="spinner-border spinner-border-sm ml-2"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div
                          :style="
                            pro.style.borderType === 'border'
                              ? getInputBorderCSS(pro.style)
                              : getInputBorderBottomCSS(pro.style)
                          "
                          class="select-div h-100"
                        >
                          <multiselect
                            :options="getAllCities"
                            :multiple="false"
                            v-model="selectedCity"
                            class="multiSelect p-0"
                            :placeholder="pro.CityPlaceholder"
                            :style="
                              QuestionPageStyle(pro.style) +
                              getFontStyle(pro.style) +
                              customInputStyles(pro.style)
                            "
                            :close-on-select="true"
                            :searchable="true"
                          >
                          </multiselect>
                        </div>
                      </div>
                      <div v-if="pro.showState" class="my-2">
                        <div
                          class="mb-1 d-flex align-items-center"
                          v-if="pro.showStateLabel"
                        >
                          <p class="m-0 p-0" :style="leadLabelStyle(pro.style)">
                            <span
                              v-if="
                                pro.isStateRequired &&
                                pro.style.textInputAlign == 'end'
                              "
                              style="color: red"
                              >*</span
                            >
                            {{ pro.StateLabel }}
                            <span
                              v-if="
                                pro.isStateRequired &&
                                (pro.style.textInputAlign == 'center' ||
                                  pro.style.textInputAlign == 'left')
                              "
                              style="color: red"
                              >*</span
                            >
                          </p>
                          <div
                            v-if="StateLoading"
                            :style="`color:${pro.style.color};`"
                            class="spinner-border spinner-border-sm ml-2"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div
                          :style="
                            pro.style.borderType === 'border'
                              ? getInputBorderCSS(pro.style)
                              : getInputBorderBottomCSS(pro.style)
                          "
                          class="select-div h-100"
                        >
                          <multiselect
                            :options="getAllStates"
                            :multiple="false"
                            v-model="selectedState"
                            class="multiSelect p-0"
                            :placeholder="pro.StatePlaceholder"
                            :required="pro.isStateRequired"
                            :style="
                              QuestionPageStyle(pro.style) +
                              getFontStyle(pro.style) +
                              customInputStyles(pro.style)
                            "
                            :close-on-select="true"
                            :searchable="true"
                          >
                          </multiselect>
                        </div>
                      </div>

                      <div v-if="pro.showCountry" class="my-2">
                        <div
                          class="mb-1 d-flex align-items-center"
                          v-if="pro.showCountryLabel"
                        >
                          <p class="m-0 p-0" :style="leadLabelStyle(pro.style)">
                            <span
                              v-if="
                                pro.isCountryRequired &&
                                pro.style.textInputAlign == 'end'
                              "
                              style="color: red"
                              >*</span
                            >
                            {{ pro.CountryLabel }}
                            <span
                              v-if="
                                pro.isCountryRequired &&
                                (pro.style.textInputAlign == 'center' ||
                                  pro.style.textInputAlign == 'left')
                              "
                              style="color: red"
                              >*</span
                            >
                          </p>
                          <div
                            v-if="CountryLoading"
                            :style="`color:${pro.style.color};`"
                            class="spinner-border spinner-border-sm ml-2"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div
                          :style="
                            pro.style.borderType === 'border'
                              ? getInputBorderCSS(pro.style)
                              : getInputBorderBottomCSS(pro.style)
                          "
                          class="select-div h-100"
                        >
                          <multiselect
                            :options="getAllCountries"
                            :multiple="false"
                            v-model="selectedCountry"
                            class="multiSelect p-0"
                            :placeholder="pro.CountryPlaceholder"
                            :required="pro.isCountryRequired"
                            :style="
                              QuestionPageStyle(pro.style) +
                              getFontStyle(pro.style) +
                              customInputStyles(pro.style)
                            "
                            :close-on-select="true"
                            :searchable="true"
                          >
                          </multiselect>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Address End -->
                  <div
v-if="pro.type == 'imageComp'"

>
<InlineImageComp :pro="pro" :isDesktopView="isDesktopView" />
</div>

                  <div
                    v-if="pro.type == 'tooltip'"
                    :style="
                      'display:flex;justify-content:' + pro.style.position + ';'
                    "
                  >
                    <button
                      :style="
                        getButtonCss(pro.style, isDesktopView) +
                        getCircularBtn(pro.isBtnRounded)
                      "
                      @mouseenter="pro.isTooltipVisible = true"
                      @mouseleave="pro.isTooltipVisible = false"
                    >
                      <span v-if="pro.useIcon">
                        <BIcon :icon="pro.selectedIcon" />
                      </span>

                      <span
                        v-else
                        :style="{
                          opacity: `${pro.style.textOpacity}%`,
                          fontSize: `${pro.style.fontSize}px`,
                        }"
                      >
                        {{ pro.text }}
                      </span>
                    </button>
                    <ToolTipComp
                      v-if="pro.isTooltipVisible"
                      :tooltipData="pro"
                      :isDesktopView="isDesktopView"
                    />
                  </div>

                  <!-- File Upload Start -->

                  <div v-if="pro.type == 'fileUpload'">
                    <div
                      :style="
                        getMarginCss(pro.style, isDesktopView) +
                        getTextAlign(pro.style)
                      "
                    >
                      <FileUploadComp
                        :pro="pro"
                        :isDesktopView="isDesktopView"
                      />
                    </div>
                  </div>
                  <!-- File Upload End -->

                  <!-- Skip Button Start -->
                  <div
                    v-if="pro.type == 'skipButton'"
                    :style="
                      'display:flex;justify-content:' + pro.style.position + ';'
                    "
                  >
                    <button :style="getButtonCss(pro.style, isDesktopView)">
                      <span
                        :style="{
                          opacity: `${pro.style.textOpacity}%`,
                          fontSize: `${pro.style.fontSize}px`,
                        }"
                      >
                        {{ pro.text }}
                      </span>
                      <!-- {{ pro.style.alignItems }} -->
                    </button>
                  </div>
                  <!-- Skip Button End -->
                </div>
              </div>
            </draggable>
          </div>

          <!-- Static Button -->
          <div>
            <div
              @click="changeStaticBtnCustomisation(getButtonIndex)"
            >
              <div
              class="designBlockElement"
                :style="
                  getHeightWidth(StaticButtonElement.style) +
                  getStaticButtonDivCss(staticButtonStyles)
                "
              >
                <div class="w-100">
                  <div
                    class="d-flex"
                    :style="
                      getBackNextButtonPosition(
                        StaticButtonElement,
                        CurrentPage.value
                      )
                    "
                  >
                    <button
                      :style="
                        getCommonButtonCSS(
                          StaticButtonElement.style,
                          isDesktopView
                        ) +
                        getBackNextButtonCss(
                          StaticButtonElement.content.backButton.style,isDesktopView
                        )
                      "
                      v-if="
                        !StaticButtonElement.content.backButton.hideBackButton
                      "
                    >
                      <span
                        :style="{
                          opacity: `${StaticButtonElement.content.backButton.style.textOpacity}%`,
                          fontSize: `${StaticButtonElement.content.backButton.style.fontSize}px`,
                        }"
                        >{{ StaticButtonElement.content.backButton.text }}</span
                      >
                    </button>
                    <button
                      :style="
                        getCommonButtonCSS(
                          StaticButtonElement.style,
                          isDesktopView
                        ) +
                        getBackNextButtonCss(
                          StaticButtonElement.content.nextButton.style,isDesktopView
                        )
                      "
                      v-if="
                        !StaticButtonElement.content.nextButton.hideNextButton
                      "
                    >
                      <span
                        :style="{
                          opacity: `${StaticButtonElement.content.nextButton.style.textOpacity}%`,
                          fontSize: `${StaticButtonElement.content.nextButton.style.fontSize}px`,
                        }"
                        >{{ StaticButtonElement.content.nextButton.text }}</span
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Static Button End -->
        </div>
      </div>

      <!-- Image Align Right -->
      <div
        class="col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0"
        v-show="showRightImage"
        :class="{
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division': isDesktopView,
        }"
        :style="
          calculateImageWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive
          )
        "
      >
        <div
          @click="activeImageCustomize"
          :class="isDesktopView ? 'image-division' : ''"
        >
          <div :style="height100(isDesktopView)">
            <LazyImage
              :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
              :style="
                getUniversalImageStyle(
                  isDesktopView,
                  pageDesign.imageBlock.style,
                  IsImageVariableWidthActive,
                  pageDesign.imageBlock
                ) +
                getImageRotation(pageDesign.imageBlock.style) +
                getImageOpacity(pageDesign.imageBlock.style)
              "
              :class="[
                `pic-${getSelectedPage.order}`,
                getImageSrc(pageDesign.imageBlock, isDesktopView) ==
                '/images/default_start_page.png'
                  ? 'default-bg'
                  : '',
              ]"
              :src="
                MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))
              "
              alt
            />
          </div>
        </div>
        <button id="clear" class="hide close-icon" @click="deleteImage">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
              fill="black"
              fill-opacity="0.5"
            />
            <path
              d="M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import centralEventBus from "./centralEventBus";
import HtmlElement from "../../../quizPreviewComp/HtmlElement.vue";
import draggable from "vuedraggable";
import ProgressBar from "../customize/pages/ProgressBar.vue";
import cssFunctionMixin from "../../mixin/cssFunction.js";
import { mapGetters, mapMutations } from "vuex";
import ToolTipComp from "../../../quizPreviewComp/ToolTipComp.vue";
import FileUploadComp from "../../../quizPreviewComp/FileUploadComp.vue";
import Multiselect from "vue-multiselect";
import { VueTelInput } from "vue-tel-input";
// import { Country, State , City  }  from 'country-state-city';
import "vue-tel-input/dist/vue-tel-input.css";
import axios from "axios";
import EditorAction from "../../../../components/EditorActions.vue"
export default {
  props: ["isDesktopView"],

  mixins: [cssFunctionMixin],
  components: {
    HtmlElement,
    draggable,
    ProgressBar,
    ToolTipComp,
    FileUploadComp,
    Multiselect,
    VueTelInput,
    AuthOtpInputComp: () =>
      import(
        /* webpackChunkName: "AuthOtpInputComp" */ "../../../quizPreviewComp/AuthOtpInputComp.vue"
      ),
      EditorAction,
      InlineImageComp: () =>
import(
/* webpackChunkName: "InlineImageComp" */ "../../../quizPreviewComp/components/InlineImageComp.vue"
),
  },
  data() {
    return {
      DummyCityData: null,
      editMode: false,
      dragging: false,
      activeSideBar: "pageStyle",
      count: 0,
      leadTextValue: "",
      dateInputValue: null,
      monthInputValue: null,
      yearInputValue: null,
      dateCustomInputValue: null,
      monthCustomInputValue: null,
      yearCustomInputValue: null,
      staticButtonStyles: {
        hoverStatus: false,
        customizeStatus: false,
      },
      quizID: null,
      dropdown: {
        showSearchBox: true,
        showFlags: true,
        width: "390px",
      },
      AllCountriesData: [],
      AllStatesData: [],
      AllCitiesData: [],
      CountryLoading: false,
      StateLoading: false,
      CityLoading: false,
      selectedCity: "",
      selectedCountry: "",
      selectedState: "",
    };
  },
  methods: {
    handleElementDrop(evt , index){
      const item = evt.dataTransfer.getData('item')
      if(item ){
        let obj = {
          index:index,
          item:item,
          currPage:this.getSelectedPage.value
        }
        centralEventBus.$emit("handleAddElementDrop", obj);
        localStorage.setItem('DragStart',false)
      }
      
    },
    async getCountryStateCityData(link) {
      const data = await axios.get(link);

      return data;
    },
    async importCountryModule() {
      let url = process.env.VUE_APP_COUNTRY_DATA_LINK;
      try {
        this.CountryLoading = true;

        let response = await this.getCountryStateCityData(url);
        if (response.status == 200) {
          this.AllCountriesData = response.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(`Error occured while fetching Country data`, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.CountryLoading = false;
      }
    },
    async importCityModule() {
      let url = process.env.VUE_APP_CITY_DATA_LINK;
      try {
        this.CityLoading = true;

        let response = await this.getCountryStateCityData(url);
        if (response.status == 200) {
          this.AllCitiesData = response.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(`Error occured while fetching City data`, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.CityLoading = false;
      }
    },
    async importStateModule() {
      let url = process.env.VUE_APP_STATE_DATA_LINK;
      try {
        this.StateLoading = true;

        let response = await this.getCountryStateCityData(url);
        if (response.status == 200) {
          this.AllStatesData = response.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show(`Error occured while fetching State data`, {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.StateLoading = false;
      }
    },

    openCalender() {
      // this.$refs.CustomDateInputRef.current.focus()
      this.$refs.CustomDateInputRef.showPicker();
    },

    InputAdjust(e) {
      let Element = e.target;
      Element.rows = 1;
      const styles = window.getComputedStyle(Element);
      const paddingTop = parseInt(styles.paddingTop);
      const paddingBottom = parseInt(styles.paddingBottom);
      const padding = paddingTop + paddingBottom;
      const initialHeight = (parseInt(styles.height) - padding) / Element.rows;
      const scrollHeight = Element.scrollHeight - padding;
      const newRows = Math.ceil(scrollHeight / initialHeight);
      Element.rows = newRows - 1 == 0 ? 1 : newRows - 1;
    },
    ...mapMutations(["setImageUpload","setCurrSidebarView"]),
    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; color:" +
        this.pageDesign.pageStyles.color +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; height:" +
        this.pageDesign.pageStyles.height +
        "px;" +
        (this.getEditorPageView == "desktop"
          ? "width:" + this.pageDesign.pageStyles.width + "px;"
          : "width:350px;");

      return design;
    },
   async changeCustomize(index) {
      if (this.staticButtonStyles.customizeStatus) {
        this.staticButtonStyles.customizeStatus = false;
      }
await this.setCurrSidebarView('pageDesign')
      centralEventBus.$emit("changeCustomize", index);
    },

  async  changeStaticBtnCustomisation(index) {
      this.staticButtonStyles.customizeStatus = true;
      await this.setCurrSidebarView('pageDesign')
      centralEventBus.$emit("changeStaticCustomisation", index);
    },

    getDivBorderCss(block) {
      if (block.customizeStatus) return "border:2px solid #4065d5 !importatnt;";
      else return "border:2px solid transparent;";
    },

    deleteBlock(index) {
      if (this.getOTPAuthIndex && this.getOTPAuthIndex == index) {
        centralEventBus.$emit("disableOtp");
      }

      centralEventBus.$emit("deleteBlock", index);
    },
    async activeImageCustomize() {
await this.setCurrSidebarView('pageDesign')
centralEventBus.$emit("activeImageCustomize");
},


    deleteImage() {
      centralEventBus.$emit("deleteImage");
    },

    getInputBorderCSS(property) {
      return (
        "border:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
    getInputBorderBottomCSS(property) {
      return (
        "border-bottom:" +
        property.borderSize +
        "px solid " +
        property.strokeColor +
        "; border-radius:" +
        property.borderRadius +
        "px; " +
        "transform: rotate(-" +
        property.strokeRotation +
        "deg);"
      );
    },
    getcheckboxRotate(property) {
      return `  transform: rotate(-${
        property.strokeRotation || 0
      }deg); --selectedCheckboxColor : ${property?.color || "#000000"};`;
    },

    getDeleteButtonCondition(pro) {
      if (pro.customizeStatus) {
        if (pro.type == "text" && this.minTextCount <= 1) return false;

        if (pro.type == "button") return false;
        return true;
      }
    },

    goToURLClicked(url) {
      let URL = url;
      let pattern = /^((http|https|ftp):\/\/)/;
      if (URL !== "") {
        if (!pattern.test(URL)) {
          URL = "http://" + URL;
        }
        window.open(URL);
      }
    },
  },
  mounted() {
    if (this.isCountryAddressFieldExist) {
      this.importCountryModule();
    }
    if (this.isCityAddressFieldExist) {
      this.importCityModule();
    }
    if (this.isStateAddressFieldExist) {
      this.importStateModule();
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        chosenClass: "custom-chosen",
      };
    },
    getQuizID() {
      const quizId = localStorage.getItem("QuizID");
      return quizId ? parseInt(quizId) : null;
    },
    isBearWithBenefitsClient() {
      return this.getQuizID
        ? process.env.VUE_APP_BEARWBENEFITS_QUIZID.includes(this.getQuizID)
        : false;
    },
    getAllCountries() {
      return this.AllCountriesData.length > 0 && this.isCountryAddressFieldExist
        ? this.AllCountriesData
        : [];
    },
    getAllStates() {
      return this.AllStatesData.length > 0 && this.isStateAddressFieldExist
        ? this.AllStatesData
        : [];
    },
    getAllCities() {
      return this.AllCitiesData.length > 0 && this.isCityAddressFieldExist
        ? this.AllCitiesData
        : [];
    },

    pageDesign() {
      return this.getSelectedPage.pageDesign;
    },

    pageDesignBlocks: {
      get() {
        return this.pageDesign.blocksArray;
      },
      set(newValue) {
        centralEventBus.$emit("handleBlocksArrayDraggable", newValue);
      },
    },

    getButtonIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return index;
    },
    getOTPAuthIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "Auth"
      );
      return index;
    },

    StaticButtonElement() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index];
    },
    staticButton() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index].staticButton;
    },
    getImageAlign(){
      if (this.pageDesign.pageStyles.useMobileImageAlign && !this.isDesktopView) {
     return  this.pageDesign.pageStyles.mobileImageAlign ;
    } else {
      return this.pageDesign.pageStyles.imageAlign;
    }
    },
    showLeftImage() {
      return (
        (this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.getImageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.getImageAlign == "left" ||
            this.getImageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.getImageAlign == "right"
      );
    },

    infoTypeEmailIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) =>
          block.type == "freeText" &&
          block.selectedInfoType.fieldName == "email"
      );
      if (index > -1) {
        return index;
      }
      return null;
    },
    ...mapGetters([
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getEditorPageView",
      "translateText",
      "getProgressBar",
      "getPlanData",
      "getPageZoom",
    ]),
    pageZoomValue() {
      return this.getPageZoom;
    },

    ProgressBarValues() {
      return this.getProgressBar;
    },
    CurrentPage() {
      return this.getSelectedPage;
    },

    IsImageAdded() {
      if (
        this.getImageSrc(this.pageDesign.imageBlock, this.isDesktopView) !==
          "" ||
        this.getUploadedImage
      )
        return true;
      return false;
    },
    IsImageVariableWidthActive() {
      return this.IsImageAdded && this.getImageAlign != "no"
        ? true
        : false;
    },
    imageAddedLayout() {
      if (this.getImageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      return "col-12 col-xs-12 col-md-6 col-sm-12  col-12 col-xs-12";
    },
    minTextCount() {
      let count = 0;
      this.pageDesign.blocksArray.forEach((x) => x.type == "text" && count++);
      return count;
    },
    isCityAddressFieldExist() {
      let index = this.pageDesign.blocksArray.findIndex(
        (e) => e.type == "address"
      );
      if (index > -1) {
        return this.pageDesign.blocksArray[index].showCity;
      }
      return false;
    },
    isStateAddressFieldExist() {
      let index = this.pageDesign.blocksArray.findIndex(
        (e) => e.type == "address"
      );
      if (index > -1) {
        return this.pageDesign.blocksArray[index].showState;
      }
      return false;
    },
    isCountryAddressFieldExist() {
      let index = this.pageDesign.blocksArray.findIndex(
        (e) => e.type == "address"
      );
      if (index > -1) {
        return this.pageDesign.blocksArray[index].showCountry;
      }
      return false;
    },
  },

  watch: {
    dateInputValue(newVal, oldVal) {
      if (newVal > 31) {
        this.dateInputValue = oldVal;
        this.$toasted.show(this.translateText("Invalid date entered."), {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },
    monthInputValue(newVal, oldVal) {
      if (newVal > 12) {
        this.monthInputValue = oldVal;
        this.$toasted.show(this.translateText("Invalid month entered."), {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },
    yearInputValue(newVal, oldVal) {
      if (newVal > 9999) {
        this.yearInputValue = oldVal;
        this.$toasted.show(this.translateText("Invalid year entered."), {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },
    dateCustomInputValue(newVal, oldVal) {
      if (newVal > 31) {
        this.dateCustomInputValue = oldVal;
        this.$toasted.show(this.translateText("Invalid date entered."), {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },
    monthCustomInputValue(newVal, oldVal) {
      if (newVal > 12) {
        this.monthCustomInputValue = oldVal;
        this.$toasted.show(this.translateText("Invalid month entered."), {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },
    yearCustomInputValue(newVal, oldVal) {
      if (newVal > 9999) {
        this.yearCustomInputValue = oldVal;
        this.$toasted.show(this.translateText("Invalid year entered."), {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },
    leadTextValue(newVal, oldVal) {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "freeTextArea"
      );

      if (
        Object.prototype.hasOwnProperty.call(
          this.pageDesign.blocksArray[index],
          `limitFreeTextArea`
        ) &&
        this.pageDesign.blocksArray[index].limitFreeTextArea
          .limitFreeTextAreaValue &&
        newVal.length >
          this.pageDesign.blocksArray[index].limitFreeTextArea
            .maxFreeTextAreaValue
      ) {
        this.leadTextValue = oldVal;
      }
    },
  },
};
</script>
<style scoped>
.designBlockElement:hover{
  border:2px solid #ada9a9 !important;
}
.lead-checkbox.custom-control-label::before {
  border: #0397cc solid 2px;
}
.lead-email-btn {
  border-bottom: 1px solid #e0dee2;
}
.lead-email-btn.dropdown-toggle::after {
  float: right;
  margin-top: 12px;
}
.condition-text {
  color: #0397cc;
}
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}
.date-input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}

.active-svg {
  padding: 3px;
  background: #b3afb6;
  border-radius: 3px;
}

.big-div {
  padding: 0px;
}

@media only screen and (max-width: 767px) {
  .big-div {
    width: 100% !important;
    padding: 0px 15px;
  }
}
@media only screen and (max-width: 1366px) {
  .big-div {
    padding: 0px 15px !important;
  }
}

.checkboxDiv input[type="checkbox"] {
  accent-color: var(--selectedCheckboxColor);
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #323232 !important;
  border: none;
}

.custom-checkbox
  .custom-control-input:checked:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
  border: none;
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
  border: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.text-setting select {
  font-size: 14px;
  padding: 0;
}

.text-input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--inputTextColor);
}
.text-input::placeholder {
  color: var(--inputPlaceholderColor);
}

.date-input::placeholder {
  color: var(--inputPlaceholderColor);
}

.CustomTextInput {
  color: var(--inputColor) !important;
}

/* DatePicker styles */
.CustomDatePicker >>> button svg path {
  fill: var(--inputColor) !important;
}
.CustomDatePicker >>> label.form-control {
  color: var(--inputColor) !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.defaultCalendar {
  width: 100%;
  padding: 0 5px;
  border: none;
  background: transparent;
}
input[type="date"]:focus {
  border-color: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  outline: 0 none !important;
}

input::-webkit-datetime-edit {
  position: relative;
  left: 0px;
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 0px;
}

input[type="date"]::-webkit-datetime-edit-year-field {
  color: var(--inputColor) !important;
}

input[type="date"]::-webkit-datetime-edit-month-field {
  color: var(--inputColor) !important;
}

input[type="date"]::-webkit-datetime-edit-day-field {
  color: var(--inputColor) !important;
}

.telephoneInput >>> input {
  background: transparent;
  color: var(--inputColor) !important;
}
.telephoneInput >>> input.vti__input::placeholder {
  color: var(--inputPlaceholderColor) !important;
}

.telephoneInput >>> .vti__dropdown .vti__dropdown-arrow {
  color: var(--inputPlaceholderColor) !important;
}
.telephoneInput >>> ul.vti__dropdown-list {
  position: unset;
}

/* MultiSelect Styles */

.select-div >>> .multiSelect {
  text-align: start;
  direction: ltr !important;
}

.select-div >>> .multiSelect .multiselect__placeholder {
  color: var(--inputPlaceholderColor);
  font-weight: 400;
  font-size: 16px;
}

.select-div >>> .multiSelect .multiselect__single {
  font-weight: 400;
  color: var(--inputColor);
}

.select-div >>> .multiselect__content-wrapper {
  text-align: left !important;
  direction: ltr !important;
  z-index: 100;
  position: relative;
}
/* DropdownStyles */
.select-div >>> .multiselect .multiselect__option--highlight,
.select-div >>> .multiselect .multiselect__option--highlight:after {
  background: var(--dropdownHoverColor) !important;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>